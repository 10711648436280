import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export function solutions() {
    window.addEventListener("load", function(){
        let window_w = window.innerWidth;
        if(window_w > 768) {
            gsap.registerPlugin(ScrollTrigger);

            const solutionsEl = document.querySelector('.j-solutions');
            const solutionsListEl = document.querySelector('.j-solutions_list');
            const solutionsTopEl = document.querySelector('.j-solutions_fixed-top');
            const solutionsBottomEl = document.querySelector('.j-solutions_fixed-bottom');
            const solutionsBottomTextEl = document.querySelector('.j-solutions_fixed-bottom_text');

            gsap.to(solutionsListEl, {
                x: () => -(solutionsListEl.clientWidth + (window_w / 5) - solutionsEl.clientWidth),
                ease: 'none',
                scrollTrigger: {
                    // markers: true,
                    trigger: solutionsEl,
                    start: 'top top',
                    end: () => `+=${solutionsListEl.clientWidth + (window_w / 5) - solutionsEl.clientWidth}`,
                    scrub: true,
                    pin: true,
                    anticipatePin: 1,
                    invalidateOnRefresh: true,
                },
            });
            // gsap.to(solutionsEl, {
                //     background: '#373434',
                //     ease: 'none',
                //     // duration: 1,
                //     scrollTrigger: {
                    //         // toggleActions: 'play pause resume reverse',
            //         trigger: solutionsEl,
            //         start: 'top top',
            //         scrub: true,
            //     },
            // });
            gsap.fromTo(solutionsTopEl, {
                top: "-20vh",
                boxShadow: '0px 30px 50px rgba(0, 0, 0, 0.8)',
            }, {
                top: "0",
                boxShadow: '0px 30px 50px rgba(0, 0, 0, 0)',
                ease: 'none',
                // duration: 1,
                scrollTrigger: {
                    // markers: true,
                    trigger: solutionsEl,
                    start: 'top bottom',
                    end: 'top 5%',
                    scrub: true,
                    invalidateOnRefresh: true,
                },
            });

            gsap.fromTo(solutionsBottomEl, {
                top: "-30vh",
                transform: 'translateY(-100%)',
            }, {
                top: "0",
                transform: 'translateY(0%)',
                ease: 'none',
                // duration: 1,
                scrollTrigger: {
                    trigger: solutionsEl,
                    start: 'top bottom',
                    end: 'top 5%',
                    scrub: true,
                    invalidateOnRefresh: true,
                },
            });

            // gsap.to(solutionsBottomTextEl, {
            //     opacity: 1,
            //     duration: .1,
            //     ease: 'none',
            //     scrollTrigger: {
            //         // markers: true,
            //         trigger: solutionsEl,
            //         start: 'bottom bottom',
            //         toggleActions: "play play reverse reverse",
            //     },
            // });
        }
    });
}
