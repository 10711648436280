import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export function outline() {
    window.addEventListener("load", function(){

        let window_w = window.innerWidth;
        if(window_w > 768) {

            gsap.registerPlugin(ScrollTrigger);

            const area = document.querySelector('.j-outline');
            const areaContents = document.querySelector('.j-outline_contents');
            let areaContentsH = areaContents.offsetHeight;

            const mockCmn = document.querySelectorAll('.j-outline_mock_cmn');
            const cardCmn = document.querySelectorAll('.j-outline_card_cmn');
            const indexCmn = document.querySelectorAll('.j-outline_index');

            area.style.height = areaContentsH + "px";

            for (let i = 0; i < cardCmn.length - 1; i++) {
                gsap.set(mockCmn[i], {
                    zIndex : mockCmn.length - i,
                });
                gsap.to(mockCmn[i], {
                    // top: '-95vh',
                    top: '-5vh',
                    transform: 'rotate(5deg)',
                    opacity: '0',
                    pointerEvents: 'none',
                    ease: 'none',
                    scrollTrigger: {
                        // markers: true,
                        trigger: cardCmn[i],
                        // start: 'bottom 95%',
                        // end: 'bottom top',
                        start: 'bottom center',
                        end: 'bottom top',
                        scrub: true,
                        invalidateOnRefresh: true,
                    }
                });
            }

            for (let i = 0; i < cardCmn.length; i++) {
                gsap.to(indexCmn[i], {
                    // color: '#F61515',
                    ease: 'none',
                    scrollTrigger: {
                        trigger: cardCmn[i],
                        // start: 'bottom 95%',
                        // end: 'bottom top',
                        start: 'bottom bottom',
                        end: 'bottom top',
                        toggleClass: {targets: indexCmn[i], className: "is-active"},
                    }
                });
            }

        }
    });
}
