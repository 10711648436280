import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export function what() {
    window.addEventListener("load", function(){
        let window_w = window.innerWidth;
        if(window_w > 768) {
            gsap.registerPlugin(ScrollTrigger);

            const whatBgEl = document.querySelector('.j-what_bg');

            gsap.fromTo(whatBgEl, {
                backgroundPositionY: "0%",
            }, {
                backgroundPositionY: "100%",
                ease: 'none',
                // duration: 1,
                scrollTrigger: {
                    trigger: whatBgEl,
                    start: 'top bottom',
                    end: 'bottom top',
                    scrub: true,
                    invalidateOnRefresh: true,
                },
            });
        }
    });
}
