export function loading() {

    var loader = document.querySelector('.j-loader');
    var loaderCatch = document.querySelector('.j-loader_catch');
    var loaderIconR = document.querySelector('.j-loader_icon_red');
    var loaderBgW = document.querySelector('.j-loading_bg_white');
    var loaderBgB = document.querySelector('.j-loading_bg_black');

    var follow = document.querySelector('.l-follow');
    var bar = document.querySelector('.l-bar');
    var body = document.querySelector('.l-body');
    var header = document.querySelector('.j-header');
    var mv = document.querySelector('.j-mv');
    var what = document.querySelector('.j-what');

    // let lastAnimation;

    /* ロード画面を非表示にする処理 */
    const stopload = () => {

        loaderCatch.animate({
            opacity: 1,
        }, {
            fill: "forwards",
            duration: 500
        });

        loaderCatch.animate({
            filter: 'blur(2px)',
        }, {
            fill: "forwards",
            delay: 1800,
            duration: 300
        });

        loaderCatch.animate({
            color: '#121212',
        }, {
            fill: "forwards",
            delay: 2100,
            duration: 100
        });

        loaderIconR.animate({
            opacity: 1,
        }, {
            // 終了時の状態で止める
            fill: "forwards",
            // 3000ミリ秒（＝3秒）かけてアニメーション
            delay: 2100,
            duration: 100,
        });

        loaderBgW.animate({
            opacity: 1,
        }, {
            // 終了時の状態で止める
            fill: "forwards",
            // 3000ミリ秒（＝3秒）かけてアニメーション
            delay: 2100,
            duration: 100,
        });
        // loaderBgB.animate({
        //     opacity: 0,
        // }, {
        //     // 終了時の状態で止める
        //     fill: "forwards",
        //     // 3000ミリ秒（＝3秒）かけてアニメーション
        //     delay: 2100,
        //     duration: 100,
        // });

        loaderCatch.animate({
            filter: 'blur(0px)',
        }, {
            fill: "forwards",
            delay: 2200,
            duration: 100
        });

        loaderCatch.animate({
            opacity: 0,
            pointerEvents: 'none',
        }, {
            // 終了時の状態で止める
            fill: "forwards",
            delay: 3300,
            duration: 400,
        });

        loaderBgW.animate({
            transform: 'translateY(-100%)',
        }, {
            // 終了時の状態で止める
            fill: "forwards",
            delay: 3300,
            duration: 900,
            easing: 'cubic-bezier(0.86, 0, 0.07, 1)',
        });

        loaderBgB.animate({
            transform: 'translateY(-100%)',
        }, {
            // 終了時の状態で止める
            fill: "forwards",
            delay: 3600,
            duration: 900,
            easing: 'cubic-bezier(0.86, 0, 0.07, 1)',
        });

        // lastAnimation();

        const lastAnimation = loader.animate({
            opacity: 0,
            pointerEvents: 'none',
        }, {
            // 終了時の状態で止める
            fill: "forwards",
            delay: 4600,
            duration: 0,
        });

        // follow.classList.add('loaded');
        // bar.classList.add('loaded');
        // body.classList.add('loaded');
        lastAnimation.finished.then(() => {
            header.classList.add('loaded');
            mv.classList.add('loaded');
            what.classList.add('loaded');
        });
    }


    /* 読み込み完了 */
    window.addEventListener('load', stopload);

    /* 10秒経ったら強制的にロード画面を非表示にする */
    setTimeout('stopload()', 3000);

}