export function mockup() {
var swiper = new Swiper(".j-mockup", {
    sliderPreView: 1,
    loop: true,
    // spaceBetween: 30,
    // effect: "fade",
    // centeredSlides: true,
    // autoplay: {
    //     delay: 2500,
    //     disableOnInteraction: false,
    // },
    // pagination: {
    //     el: ".swiper-pagination",
    //     type: "fraction",
    // },
    navigation: {
        nextEl: ".swiper-button-next",
        // prevEl: ".swiper-button-prev",
    },
});
}