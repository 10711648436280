import { preloadImages } from './modules/utils';
// import { fv } from './modules/fv';
// import { mv } from './modules/mv';
import { loading } from './modules/loading';
import { mockup } from './modules/mockup';
import { outline } from './modules/outline';
import { solutions } from './modules/solutions';
import { what } from './modules/what';


loading();
Promise.all([preloadImages()]).then(() => {
    // fv();
    // mv();
    what();
    solutions();
    outline();
    mockup();
});